import React, { useLayoutEffect, useRef, useEffect } from 'react';
import '../../App.css';
import { Route, Routes, useLocation } from 'react-router-dom'
import Navbar from '../shared/navbar.js';
import Footer from '../shared/footer.js';
import Error404 from '../shared/error404.js';
import Services from '../mainsite/services/services.js';
import Process from '../mainsite/services/process.js';
import Projects from '../mainsite/services/projects.js';
import Technology from '../mainsite/services/technology.js';
import Team from '../mainsite/company/team.js';
import Careers from '../mainsite/company/careers.js';
import About from '../mainsite/company/about.js';
import Contact from '../mainsite/contact.js';
import Privacy from '../mainsite/terms/privacy.js';
import Cookie from '../mainsite/terms/cookie.js';
import Blog from '../mainsite/resources/blog.js';
import Bespoke from '../mainsite/resources/bespoke.js';
import Responsibility from '../mainsite/terms/responsibility.js';
import Terms from '../mainsite/terms/terms.js';
import Contacts from '../loggedIn/manageData/contacts.js';
import ToInvoice from '../loggedIn/reports/toinvoice.js';
import VAT from '../loggedIn/reports/vat.js';
import Transactions from '../loggedIn/reports/transactions.js';
import Categories from '../loggedIn/reports/categories.js';
import CostCentre from '../loggedIn/reports/costCentre.js';
import ContactForm from '../loggedIn/reports/contactForm.js';
import Tasks from '../loggedIn/manageData/tasks.js';
import Users from '../loggedIn/manageData/users.js';
import CCRecon from '../loggedIn/manageData/ccRecon.js';
import ClientProjects from '../loggedIn/manageData/clientProjects.js';
import CodeGenerator from '../loggedIn/manageData/codeGenerator.js';
import Home from '../mainsite/homepage.js';
import Dashboard from '../loggedIn/dashboard.js';
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import ServiceDetail from '../mainsite/servicesDetail.js';
import ProjectDetail from '../mainsite/projectDetail.js';
import { CookieProvider } from '../shared/cookieContext.js';
import CookieBanner from '../shared/cookieBanner.js';
import ProjectManagement from '../loggedIn/manageData/projectManagement';
import BlogManagement from '../loggedIn/manageData/blogManagement';

const PrivateRoute = () => {

    const { accounts } = useMsal();
    const name = accounts[0] && accounts[0].name;
    const scrollableRef = useRef(null);

    const location = useLocation();

    useLayoutEffect(() => {
        // console.log(location.pathname)
        if (scrollableRef.current) {
            scrollableRef.current.scrollTo({ top: 0 });
        }
    }, [location.pathname]);

    useEffect(() => {
        const scrollableElement = scrollableRef.current; // Store the current value

        const handleScroll = () => {
            const elements = document.querySelectorAll('.div-container');
            elements.forEach((element) => {
                const positionFromTop = element.getBoundingClientRect().top;
                const threshold = scrollableElement.clientHeight + 0; // Use the stored value

                if (positionFromTop < threshold) {
                    element.classList.add('visible');
                } else {
                    element.classList.remove('visible');
                }
            });
            const elements1 = document.querySelectorAll('.block-left-scroll');
            elements1.forEach((elements1) => {
                const positionFromTop = elements1.getBoundingClientRect().top;
                const threshold = scrollableElement.clientHeight + 0; // Use the stored value

                if (positionFromTop < threshold) {
                    elements1.classList.add('visible');
                }
            });
            const elements2 = document.querySelectorAll('.block-right-scroll');
            elements2.forEach((elements2) => {
                const positionFromTop = elements2.getBoundingClientRect().top;
                const threshold = scrollableElement.clientHeight + 0; // Use the stored value

                if (positionFromTop < threshold) {
                    elements2.classList.add('visible');
                }
            });
        };

        scrollableElement.addEventListener('scroll', handleScroll);

        // Clean up event listeners
        return () => {
            scrollableElement.removeEventListener('scroll', handleScroll); // Use the stored value
        };
    }, []);

    useEffect(() => {
        const setCanonicalUrl = () => {
            const canonicalUrl = `${window.location.origin}${window.location.pathname}`;
            let link = document.querySelector("link[rel='canonical']");

            if (!link) {
                link = document.createElement('link');
                link.setAttribute('rel', 'canonical');
                document.head.appendChild(link);
            }

            link.setAttribute('href', canonicalUrl);
        };

        setCanonicalUrl();
    }, [location]);




    return (
        <>

            <Navbar loggedInName={name} />
            <div className="scroll-container" ref={scrollableRef}>

                <UnauthenticatedTemplate>

                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/services" element={<Services />} />
                        <Route path="/team" element={<Team />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/careers" element={<Careers />} />
                        <Route path="/privacy" element={<Privacy />} />
                        <Route path="/cookie" element={<Cookie />} />
                        <Route path="/terms" element={<Terms />} />
                        <Route path="/responsibility" element={<Responsibility />} />
                        <Route path="/bespoke/:blogId" element={<Bespoke />} />
                        <Route path="/blog" element={<Blog />} />
                        <Route path="/technology" element={<Technology />} />
                        <Route path="/process" element={<Process />} />
                        <Route path="/projects" element={<Projects />} />
                        <Route path="/projects/:projectId" element={<ProjectDetail />} />
                        <Route path="/services/:serviceId" element={<ServiceDetail />} />
                        <Route path="*" element={<Error404 />} />
                    </Routes>
                    <Footer />

                    <CookieProvider>
                        <CookieBanner>
                        </CookieBanner >
                    </CookieProvider>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                    <Routes>

                        <Route path="/" element={<Dashboard />} />
                        <Route path="/projects" element={<Contacts />} />
                        <Route path="/tasks" element={<Tasks />} />
                        <Route path="/toinvoice" element={<ToInvoice displayModal={false} />} />
                        <Route path="/vat" element={<VAT />} />
                        <Route path="/transactions" element={<Transactions />} />
                        <Route path="/categories" element={<Categories />} />
                        <Route path="/costCentre" element={<CostCentre />} />
                        <Route path="/contactform" element={<ContactForm />} />
                        <Route path="/users" element={<Users />} />
                        <Route path="/ccrecon" element={<CCRecon />} />
                        <Route path="/clientprojects" element={<ClientProjects />} />
                        <Route path="/codegenerator" element={<CodeGenerator />} />
                        <Route path="/blogmanagement" element={<BlogManagement />} />
                        <Route path="/projectmanagement" element={<ProjectManagement />} />
                        <Route path="*" element={<Error404 />} />
                    </Routes>

                </AuthenticatedTemplate>
            </div>

        </>
    );
}

export default PrivateRoute;

import axios from "./naAxiosInstance";

const base = {

    getAll: async (url) => {
        const response = await axios.get(url,
            { withCredentials: false });
        return response.data;
    },

    get: async (url, id) => {
        const response = await axios.get(url + '/' + id,
            { withCredentials: false });
        return response.data;
    },

    qry: async (url, id) => {
        const response = await axios.get(url + '/' + id,
            { withCredentials: false });
        return response.data;
    },

    add: async (url, data) => {
        const response = await axios.post(url, data, { withCredentials: false });
        return response;
    },

    update: async (url, data) => {
        const response = await axios.put(url, data, { withCredentials: false });
        return response;
    },

    delete: async (url, id) => {
        const response = await axios.put(url + '/' + id, null, { withCredentials: false });
        return response.status;
    }
}

export default base;
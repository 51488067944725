import { useState, useLayoutEffect } from 'react';
import '../../assets/css/Navbar.css'
// import "../../assets/css/main.scss";
// import '../../assets/css/bpcore.css'
import { Navbar, Collapse, Nav, NavbarToggler, UncontrolledDropdown, DropdownMenu, DropdownToggle, Row, Col, Button } from 'reactstrap'
import { Link } from 'react-router-dom';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';
import { ReactComponent as Down16 } from '../../assets/icons/down-large-16.svg'
import { ReactComponent as Up16 } from '../../assets/icons/up-large-16.svg'
import { AiOutlineCluster, AiOutlineCode, AiOutlineFileText, AiOutlineGroup, AiOutlineRead, AiOutlineProfile, AiOutlineInfoCircle, AiOutlineInteraction, AiOutlineLogout, AiOutlineMessage, AiOutlineOrderedList, AiOutlinePartition, AiOutlinePoundCircle, AiOutlineProject, AiOutlineReconciliation, AiOutlineTeam, AiOutlineUser, AiOutlineWallet } from 'react-icons/ai';
// import { IoLogoReact } from 'react-icons/io5'
// import { loginRequest } from "../../authconfig";
import { useLocation } from 'react-router-dom'
import { ReactComponent as Remove48 } from '../../assets/icons/remove48.svg'
import { ReactComponent as Hamburger48 } from '../../assets/icons/hamburger-48.svg'

const Navibar = (props) => {

    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();



    function handleLogin() {
        if (!isAuthenticated && inProgress === InteractionStatus.None) {

            instance.handleRedirectPromise()
                .then((tokenResponse) => {
                    if (!tokenResponse) {
                        const accounts = instance.getAllAccounts();
                        if (accounts.length === 0) {
                            instance.loginRedirect();
                        }
                    } else {
                        alert("error during handleRedirectPromise")
                    }
                })
                .catch(err => {
                    console.error(err);
                    alert(err);
                });
        };
    };

    function handleLogout() {
        instance.logoutRedirect().catch(e => {
            console.error(e);
        });
    }

    // const [open, setOpen] = useState('');
    // const accordiantoggle = (id) => {
    //     if (open === id) {
    //         setOpen();
    //     } else {
    //         setOpen(id);
    //     }
    // };

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [companyOpen, setCompanyOpen] = useState(false);
    const [manageDataOpen, setManageDataOpen] = useState(false);
    const [reportsOpen, setReportsOpen] = useState(false);
    const [profileOpen, setProfileOpen] = useState(false);

    const handleDropdown = (event) => {
        var id = event.target.id
        if (id === "") {
            id = event.target.nearestViewportElement.id
        }
        var idArray = id.split(":")
        var elementID = idArray[0]
        switch (event.type) {
            case "mouseenter":
                if (window.innerWidth >= 992) {
                    if (elementID === "work") {
                        setDropdownOpen(true);
                        setCompanyOpen(false);
                        setManageDataOpen(false);
                        setReportsOpen(false);

                    }
                    if (elementID === "company") {
                        setDropdownOpen(false);
                        setCompanyOpen(true);
                        setManageDataOpen(false);
                        setReportsOpen(false);
                        setProfileOpen(false);
                    }
                    if (elementID === "managedata") {
                        setDropdownOpen(false);
                        setCompanyOpen(false);
                        setManageDataOpen(true);
                        setReportsOpen(false);
                        setProfileOpen(false);
                    }
                    if (elementID === "reports") {
                        setDropdownOpen(false);
                        setCompanyOpen(false);
                        setManageDataOpen(false);
                        setReportsOpen(true);
                        setProfileOpen(false);

                    }
                    if (elementID === "profile") {
                        setDropdownOpen(false);
                        setCompanyOpen(false);
                        setManageDataOpen(false);
                        setReportsOpen(false);
                        setProfileOpen(true);
                    }
                }
                break;
            case "mouseleave":
                if (window.innerWidth >= 992) {

                    if (elementID === "work") {
                        setDropdownOpen(false);

                    }
                    if (elementID === "company") {
                        setCompanyOpen(false);
                    }
                    if (elementID === "managedata") {
                        setManageDataOpen(false);
                    }
                    if (elementID === "reports") {
                        setReportsOpen(false);
                    }
                    if (elementID === "profile") {
                        setProfileOpen(false);
                    }
                }
                break;
            case "click":
                if (window.innerWidth <= 992) {
                    // console.log(manageDataOpen)
                    if (elementID === "work") {
                        setDropdownOpen(!dropdownOpen);
                        setCompanyOpen(false);
                        setReportsOpen(false);
                        setManageDataOpen(false);
                        setProfileOpen(false);

                    }
                    if (elementID === "company") {
                        setCompanyOpen(!companyOpen);
                        setDropdownOpen(false);
                        setReportsOpen(false);
                        setManageDataOpen(false);
                        setProfileOpen(false);
                    }
                    if (elementID === "managedata") {
                        // console.log("here")
                        setManageDataOpen(!manageDataOpen);
                        setReportsOpen(false);
                        setDropdownOpen(false);
                        setCompanyOpen(false);
                        setProfileOpen(false);
                    }
                    if (elementID === "reports") {
                        setReportsOpen(!reportsOpen);
                        setDropdownOpen(false);
                        setCompanyOpen(false);
                        setManageDataOpen(false);
                        setProfileOpen(false);
                    }
                    if (elementID === "profile") {
                        setReportsOpen(false);
                        setDropdownOpen(false);
                        setCompanyOpen(false);
                        setManageDataOpen(false);
                        setProfileOpen(!profileOpen);
                    }
                }
                break;
            default:
                break
        }

    }

    const handleLink = () => {
        setDropdownOpen(false);
        setCompanyOpen(false);
        setManageDataOpen(false);
        setReportsOpen(false);
        setProfileOpen(false);
    }

    const location = useLocation();

    useLayoutEffect(() => {
        setIsOpen(false);
    }, [location]);

    return (

        <Navbar className="bg-Nav" light expand="lg">
            <div className="mt-1 mb-1 ms-2" >
                <Link to='/' className="nav-brand" >
                    <span>Mobyte
                    </span>
                </Link>
            </div>
            {!isAuthenticated ?
                <>
                    <NavbarToggler onClick={toggle} className="p-0 border-0">
                        {isOpen ? <Remove48 /> : <Hamburger48 />}
                    </NavbarToggler>
                    <Collapse isOpen={isOpen} navbar>
                        <Nav navbar className="ms-auto" >
                            <UncontrolledDropdown
                                id="work:uncontrolledDrop"
                                inNavbar
                                nav onMouseEnter={handleDropdown} onMouseLeave={handleDropdown} onClick={handleDropdown}
                                isOpen={dropdownOpen}
                                style={{ cursor: "default" }}
                            >
                                <DropdownToggle
                                    nav
                                    className="me-5 ms-2"
                                    id="work:dropToggle"
                                    style={{ cursor: "default" }}
                                >
                                    <span style={{ cursor: "default" }} className="nav-text" id="work:span" >Our Work {dropdownOpen ? <span ><Up16 id="work:img1" /></span> : <span ><Down16 id="work:img2" /></span>}
                                    </span>
                                </DropdownToggle>
                                <DropdownMenu
                                    className="dropdown-menu-left"
                                    id="work:dropMenu"
                                >
                                    <Row id="work:row">
                                        <Col xs="12" id="work:col" >
                                            <Nav vertical id="work:nav">
                                                <Col id="work:Col1" className="p-2 nav-drp-link" tag={Link} to="/services" onClick={handleLink}>
                                                    <AiOutlineCode size={20} id="work:icon1" />
                                                    &nbsp;&nbsp;
                                                    <span id="work:span1">
                                                        Services
                                                    </span>
                                                </Col>
                                                <Col className="p-2 nav-drp-link" tag={Link} to="/process" onClick={handleLink} id="work:Col2" >
                                                    <AiOutlineOrderedList size={20} id="work:icon2" />
                                                    &nbsp;&nbsp;
                                                    <span id="work:span2">
                                                        Process
                                                    </span>
                                                </Col>
                                                <Col className="p-2 nav-drp-link" tag={Link} to="/projects" onClick={handleLink} id="work:Col3">
                                                    <AiOutlineProject size={20} id="work:icon3" />
                                                    &nbsp;&nbsp;
                                                    <span id="work:span3">
                                                        Projects
                                                    </span>
                                                </Col>
                                            </Nav>
                                        </Col>
                                    </Row>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <UncontrolledDropdown
                                inNavbar
                                id="company:uncontrolledDrop"

                                nav onMouseEnter={handleDropdown} onMouseLeave={handleDropdown} onClick={handleDropdown}
                                isOpen={companyOpen}
                                style={{ cursor: "default" }}
                            >
                                <DropdownToggle
                                    nav
                                    className="me-5 ms-2"
                                    id="company:dropToggle"
                                    style={{ cursor: "default" }}
                                >
                                    <span id="company:span" style={{ cursor: "default" }} className="nav-text" >Company {companyOpen ? <span ><Up16 id="company:img1" /></span> : <span ><Down16 id="company:img2" /></span>}
                                    </span>
                                </DropdownToggle>

                                <DropdownMenu
                                    className="dropdown-menu-left"
                                    id="company:dropMenu"

                                >
                                    <Row id="company:row">
                                        <Col xs="12" id="company:col"  >
                                            <Nav vertical id="company:nav">
                                                <Col className="p-2 nav-drp-link" tag={Link} to="/about" onClick={handleLink} id="company:Col1">
                                                    <AiOutlineInfoCircle size={20} id="company:icon1" />
                                                    &nbsp;&nbsp;
                                                    <span id="company:span1">
                                                        About Us
                                                    </span>
                                                </Col>
                                                <Col className="p-2 nav-drp-link" tag={Link} to="/team" onClick={handleLink} id="company:Col2">
                                                    <AiOutlineTeam size={20} id="company:icon2" />
                                                    &nbsp;&nbsp;
                                                    <span id="company:span2">
                                                        Team
                                                    </span>
                                                </Col>
                                                <Col className="p-2 nav-drp-link" tag={Link} to="/careers" onClick={handleLink} id="company:Col3">
                                                    <AiOutlineCluster size={20} id="company:icon3" />
                                                    &nbsp;&nbsp;
                                                    <span id="company:span3">
                                                        Careers
                                                    </span>
                                                </Col>

                                            </Nav>
                                        </Col>

                                    </Row>
                                </DropdownMenu>
                            </UncontrolledDropdown>



                            <Col className="me-5 mt-2 mb-2 ms-2 pe-2" >
                                <div className="nav-text" style={{ cursor: "pointer" }} onClick={handleLogin} >
                                    <span >Login
                                    </span>
                                </div>
                            </Col>
                            <Link to="/contact">
                                <Button className="main-button btn-steel-blue ms-2" size="md">Get in touch</Button>
                            </Link>
                        </Nav>
                    </Collapse>


                </>
                : <>
                    <NavbarToggler onClick={toggle} className="p-0 border-0">
                        {isOpen ? <Remove48 /> : <Hamburger48 />}
                    </NavbarToggler>
                    <Collapse isOpen={isOpen} navbar>
                        <Nav navbar className="ms-auto" >
                            <Col className="me-5 mt-2 mb-2 ms-2 pe-2 nav-drp-link" tag={Link} to="/">
                                <div className="nav-text" >
                                    <span>Dashboard
                                    </span>
                                </div>
                            </Col>
                            <UncontrolledDropdown
                                id="managedata:uncontrolledDrop"
                                inNavbar
                                nav onMouseEnter={handleDropdown} onMouseLeave={handleDropdown} onClick={handleDropdown}
                                isOpen={manageDataOpen}
                                style={{ cursor: "default" }}
                            >
                                <DropdownToggle
                                    nav
                                    className="me-5 ms-2"
                                    id="managedata:dropToggle"
                                    style={{ cursor: "default" }}
                                >
                                    <span className="nav-text" id="managedata:span" >Manage Data <span alt="" ><Down16 id="managedata:image" /></span>
                                    </span>

                                </DropdownToggle>
                                <DropdownMenu
                                    className="dropdown-menu-left-wide"
                                    id="managedata:dropMenu"
                                >
                                    <Col className="p-2 nav-drp-link" tag={Link} to="/ccrecon" onClick={handleLink} id="managedata:Col1">
                                        <AiOutlineReconciliation size={20} id="managedata:icon1" />
                                        &nbsp;&nbsp;
                                        <span id="managedata:span1">
                                            Reconciliation Data
                                        </span>
                                    </Col>
                                    <Col className="p-2 nav-drp-link" tag={Link} to="/clientprojects" onClick={handleLink} id="managedata:Col5">
                                        <AiOutlineProject size={20} id="managedata:icon5" />
                                        &nbsp;&nbsp;
                                        <span id="managedata:span5">
                                            Client Projects
                                        </span>
                                    </Col>
                                    <Col className="p-2 nav-drp-link" tag={Link} to="/projects" onClick={handleLink} id="managedata:Col2">
                                        <AiOutlinePartition size={20} id="managedata:icon2" />
                                        &nbsp;&nbsp;
                                        <span id="managedata:span2">
                                            Projects
                                        </span>
                                    </Col>
                                    <Col className="p-2 nav-drp-link" tag={Link} to="/tasks" onClick={handleLink} id="managedata:Col3">
                                        <AiOutlineInteraction size={20} id="managedata:icon3" />
                                        &nbsp;&nbsp;
                                        <span id="managedata:span3">
                                            Tasks
                                        </span>
                                    </Col>
                                    <Col className="p-2 nav-drp-link" tag={Link} to="/users" onClick={handleLink} id="managedata:Col4">
                                        <AiOutlineUser size={20} id="managedata:icon4" />
                                        &nbsp;&nbsp;
                                        <span id="managedata:span4">
                                            Users
                                        </span>
                                    </Col>
                                    <Col className="p-2 nav-drp-link" tag={Link} to="/codegenerator" onClick={handleLink} id="managedata:Col5">
                                        <AiOutlineCode size={20} id="managedata:icon5" />
                                        &nbsp;&nbsp;
                                        <span id="managedata:span5">
                                            Code Generator
                                        </span>
                                    </Col>
                                    <Col className="p-2 nav-drp-link" tag={Link} to="/blogmanagement" onClick={handleLink} id="managedata:Col6">
                                        <AiOutlineRead size={20} id="managedata:icon6" />
                                        &nbsp;&nbsp;
                                        <span id="managedata:span6">
                                            Blog
                                        </span>
                                    </Col>
                                    <Col className="p-2 nav-drp-link" tag={Link} to="/projectmanagement" onClick={handleLink} id="managedata:Col6">
                                        <AiOutlineProfile size={20} id="managedata:icon7" />
                                        &nbsp;&nbsp;
                                        <span id="managedata:span7">
                                            Project Management
                                        </span>
                                    </Col>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <UncontrolledDropdown
                                id="reports:uncontrolledDrop"
                                inNavbar
                                nav onMouseEnter={handleDropdown} onMouseLeave={handleDropdown} onClick={handleDropdown}
                                isOpen={reportsOpen}
                                style={{ cursor: "default" }}
                            >
                                <DropdownToggle
                                    nav
                                    className="me-5 ms-2"
                                    id="reports:dropToggle"
                                    style={{ cursor: "default" }}
                                >
                                    <span className="nav-text" id="reports:span">Reports <span alt="" ><Down16 id="reports:image" /></span>
                                    </span>

                                </DropdownToggle>
                                <DropdownMenu
                                    className="dropdown-menu-left-wide"
                                    id="reports:dropMenu"
                                >
                                    <Col className="p-2 nav-drp-link" tag={Link} to="/costCentre" onClick={handleLink} id="reports:Col1">
                                        <AiOutlineWallet size={20} id="reports:icon1" />
                                        &nbsp;&nbsp;
                                        <span id="reports:span1">
                                            Cost Centre Allocation
                                        </span>
                                    </Col>
                                    <Col className="p-2 nav-drp-link" tag={Link} to="/transactions" onClick={handleLink} id="reports:Col2">
                                        <AiOutlineOrderedList size={20} id="reports:icon2" />
                                        &nbsp;&nbsp;
                                        <span id="reports:span2">
                                            Transactions
                                        </span>
                                    </Col>
                                    <Col className="p-2 nav-drp-link" tag={Link} to="/vat" onClick={handleLink} id="reports:Col3">
                                        <AiOutlinePoundCircle size={20} id="reports:icon3" />
                                        &nbsp;&nbsp;
                                        <span id="reports:span3">
                                            VAT Receipts
                                        </span>
                                    </Col>
                                    <Col className="p-2 nav-drp-link" tag={Link} to="/categories" onClick={handleLink} id="reports:Col4">
                                        <AiOutlineGroup size={20} id="reports:icon4" />
                                        &nbsp;&nbsp;
                                        <span id="reports:span4">
                                            Tax Categories
                                        </span>
                                    </Col>
                                    <Col className="p-2 nav-drp-link" tag={Link} to="/toinvoice" onClick={handleLink} id="reports:Col5">
                                        <AiOutlineFileText size={20} id="reports:icon5" />
                                        &nbsp;&nbsp;
                                        <span id="reports:span5">
                                            To Invoice
                                        </span>
                                    </Col>
                                    <Col className="p-2 nav-drp-link" tag={Link} to="/contactform" onClick={handleLink} id="reports:Col6">
                                        <AiOutlineMessage size={20} id="reports:icon6" />
                                        &nbsp;&nbsp;
                                        <span id="reports:span6">
                                            Contact Form
                                        </span>
                                    </Col>

                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <UncontrolledDropdown
                                inNavbar
                                id="profile:uncontrolledDrop"

                                nav onMouseEnter={handleDropdown} onMouseLeave={handleDropdown} onClick={handleDropdown}
                                isOpen={profileOpen}
                                style={{ cursor: "default" }}
                            >
                                <DropdownToggle
                                    nav
                                    className="me-5 ms-2"
                                    id="profile:dropToggle"
                                    style={{ cursor: "default" }}
                                >
                                    <span className="nav-text" id="profile:span" >{props.loggedInName} <span alt="" ><Down16 id="profile:image" /></span>
                                    </span>

                                </DropdownToggle>
                                <DropdownMenu
                                    className="dropdown-menu-left"
                                    id="profile:dropMenu"

                                >

                                    <Col className="p-2" id="profile:Col">
                                        <div className="nav-text mt-1 ms-3" style={{ cursor: "pointer" }} onClick={handleLogout} id="profile:div">
                                            <AiOutlineLogout size={20} id="profile:icon" />
                                            &nbsp;&nbsp;
                                            <span id="profile:span">
                                                Logout
                                            </span>
                                        </div>

                                    </Col>


                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </>
            }
        </Navbar >
    );
}

export default Navibar
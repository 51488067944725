import React, { useState, useCallback, useEffect } from 'react'
import { Card, CardBody, CardHeader, Row, Col, Button } from 'reactstrap'
import '../../assets/css/Main.css'
import { AiFillDashboard, AiFillPoundCircle, AiFillClockCircle, AiFillCreditCard } from 'react-icons/ai'
import base from '../../services/BaseService'
import Spinner from '../shared/loading'
import FreeAgent from '../../assets/icons/freeagent-mark.svg'
import Invoice from '../../assets/icons/invoice.svg'
import Refresh from '../../assets/icons/refresh.svg'
import CheckOutline from '../../assets/icons/check_outline.svg'
import refresh from '../../assets/icons/refresh24.svg'
import InvoiceModal from './reports/toinvoice'
import { AuthenticatedTemplate } from "@azure/msal-react";
import ConfirmToast from '../shared/confirmToast'


import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS, CategoryScale,
    LinearScale,
    BarElement, LineElement, PointElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ReactSelect from 'react-select'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement, PointElement, LineElement,
    ChartDataLabels,
    Title,
    Tooltip,
    Legend
);

const Dashboard = () => {
    const [style1, setStyle1] = useState("b-width pc-btn-dark ms-1 mb-1");
    const [style2, setStyle2] = useState("b-width pc-btn ms-1 mb-1");
    // const [bankData, setBankData] = useState([{ "code": "01_AMX_LW", "balance": -2072.71, "id": 15 }, { "code": "02_HAL_LW", "balance": -1056.41, "id": 9 }, { "code": "03_CP1_JW", "balance": 0.00, "id": 2 }, { "code": "10_HAL_LW", "balance": 1637.88, "id": 6 }, { "code": "12_HAL_JW", "balance": 719.19, "id": 10 }, { "code": "14_BAR_LW", "balance": 250.00, "id": 8 }, { "code": "15_NAT_LW", "balance": 6330.04, "id": 17 }, { "code": "24_HAL_JW", "balance": 31265.57, "id": 16 }]);
    const [bankValues, setBankValues] = useState([]);
    const [bankValues1, setBankValues1] = useState([]);
    const [bankLabels, setBankLabels] = useState([]);
    const [tsValues, setTsValues] = useState([]);
    const [tsValues1, setTsValues1] = useState([]);
    const [trValues, setTrValues] = useState([]);
    const [tsLabels, setTsLabels] = useState([]);
    const [trLabels, setTrLabels] = useState([]);
    const [vatValue, setVatValue] = useState([]);
    const [ccIn, setCCIn] = useState([]);
    const [ccOut, setCCOut] = useState([]);
    const [ccNet, setCCNet] = useState([]);
    const [ccTotal, setCCTotal] = useState([]);
    const [sumBillable, setSumBillable] = useState([]);
    const [sumUnbillable, setSumUnbillable] = useState([]);
    const [lastRefreshed, setLastRefreshed] = useState([]);
    const [periodDate, setPeriodDate] = useState([]);
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState();
    const [loading1, setLoading1] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [loading3, setLoading3] = useState(true);
    const [loading4, setLoading4] = useState(true);
    const [loading5, setLoading5] = useState(false);
    const [faRefresh, setFARefresh] = useState(false);
    const [refreshPage, setRefreshPage] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [toggleModal, setToggleModal] = useState(false)
    const [invCreate, setInvCreate] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [handleConfirm, setHandleConfirm] = useState("")

    useEffect(() => {
        document.title = 'Dashboard | Mobyte';
    }, []);

    // set value for default selection
    const [selectedValue, setSelectedValue] = useState("");
    // const [selectedLabel, setSelectedLabel] = useState("");

    const openModal = () => {
        setInvCreate(false);
        setShowModal(!showModal)
        //console.log(showModal)
    }

    const openModalCreate = () => {
        setInvCreate(true);

        setShowConfirm(true)

        //console.log(showModal)
    }

    useEffect(() => {
        if (handleConfirm === "Yes") {
            setLoading4(true);
            // setLoading5(true);
            setLastRefreshed("Refresh In Progress...");
            base.getAll("FreeAgent/refresh").then(() => {
                setLastRefreshed("Refreshing Dashboard...");
                var date = new Date().getTime()
                setFARefresh(date)
                setShowModal(true)
                setLoading4(false);
            });
        }
        if (handleConfirm === "No") {
            setShowModal(true)
        }
        setShowConfirm(false)
        setHandleConfirm("")
    }, [handleConfirm]);

    const [smallScreen, setSmallScreen] = useState(window.innerWidth < 750 ? false : true)


    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 750) {
                setSmallScreen(false)
            } else {
                setSmallScreen(true)
            }
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);




    // useEffect(() => {


    // }, [window.innerWidth]);



    const closeModal = () => {
        setShowModal(showModal => false)
        // setExpLoading(true)
        setToggleModal(!toggleModal)
    }

    // handle onChange event of the dropdown and get value
    // React-select get value on change
    const handleChange = e => {
        setStyle2("b-width pc-btn me-1");
        setStyle1("b-width pc-btn-dark me-1")
        setSelectedValue(e.value);
        // setSelectedLabel(e.label)
        // //console.log(e.value);
        // //console.log(e.label);

    }

    const formatNumber = useCallback((number) => {
        // this puts commas into the number eg 1000 goes to 1,000,
        return (number)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
    }, []);

    const handleClick = (e) => {
        if (e.target.name === 'Project') {
            setLoading2(true);
            setStyle2("b-width pc-btn me-1");
            setStyle1("b-width pc-btn-dark me-1")
            base.getAll("Report/tsperiod/" + selectedValue).then((tsRes) => {
                // setBankData(budgetRes);
                setTsLabels(tsRes.map(function (item) {
                    return item.projectName;
                }));
                setTsValues(tsRes.map(function (item) {
                    return item.sumBillable;
                }));
                setTsValues1(tsRes.map(function (item) {
                    return item.sumUnbillable;
                }));
                setLoading2(false)

            });
            // setTsLabels(tsLabels1)
        };
        if (e.target.name === 'Task') {
            setLoading2(true);
            setStyle1("b-width pc-btn me-1");
            setStyle2("b-width pc-btn-dark me-1")
            base.getAll("Report/tsperiod/task/" + selectedValue).then((tsRes) => {
                // setBankData(budgetRes);
                setTsLabels(tsRes.map(function (item) {
                    return item.taskName;
                }));
                setTsValues(tsRes.map(function (item) {
                    return item.sumBillable;
                }));
                setTsValues1(tsRes.map(function (item) {
                    return item.sumUnbillable;
                }));
                setLoading2(false)

            });
            // setTsLabels(tsLabels1)
        }
    };

    const openFreeAgent = (() => {
        window.open("https://mobyte.freeagent.com/overview");
    });

    // const options1 = {
    //     responsive: true,
    //     scales: {
    //         x: {
    //             stacked: true,
    //         },
    //         y: {
    //             stacked: true,
    //             min: 5
    //         },
    //     },
    // };

    const options2 = {
        responsive: true,
    };

    // scales: {
    //     // x1: {
    //     //     position: 'bottom',
    //     //     ticks: {
    //     //         callback: function (value, index, values) {
    //     //             return this.getLabelForValue(value).split(';')[0];
    //     //         }
    //     //     }
    //     // },
    //     // x2: {
    //     //     position: 'bottom',
    //     //     ticks: {
    //     //         callback: function (value, index, values) {
    //     //             return this.getLabelForValue(value).split(';')[1];
    //     //         }
    //     //     }
    //     // },
    //     y: {
    //         // stacked: true,
    //         // min: 0,
    //         suggestedMax: 10
    //     },
    // },



    useEffect(() => {
        const getUser = async () => {
            await base.getAll("Report/user").then((userRes) => {
                // setBankData(budgetRes);
                setUser(userRes.userLevel)
                // console.log("getting user info after token refresh " + userRes.userLevel)
                setSelectedValue(userRes.idUser)
                //console.log(userRes.userLevel);
                //console.log(userRes.idUser);
            })
        }
        getUser();
    }, [])


    useEffect(() => {


        // setCCTotal(" ")
        // setCCIn(" ")
        // setCCOut(" ")
        // setCCNet(" ")
        // setSumBillable(" ")
        // setSumUnbillable(" ")

        if (selectedValue !== "") {
            // console.log(selectedValue)

            const getCCData = async () => {
                await base.getAll("Report/ccfinal/" + selectedValue).then((ccRes) => {
                    // setBankData(budgetRes);
                    setBankLabels(ccRes.map(function (item) {
                        return item.period;
                    }));
                    setBankValues(ccRes.map(function (item) {
                        return item.ccIn;
                    }));
                    setBankValues1(ccRes.map(function (item) {
                        return item.ccOut;
                    }));
                    const result = ccRes.reduce((total, currentValue) => total = total + currentValue.ccIn, 0);
                    const result1 = ccRes.reduce((total, currentValue) => total = total + currentValue.ccOut, 0);
                    //console.log(result);
                    //console.log(result1);
                    setCCIn(formatNumber(result));
                    setCCOut(formatNumber(result1));
                    setCCNet(formatNumber(result - result1))
                    setCCTotal(formatNumber(ccRes[0].ccTotal))

                });
                setLoading1(false)

            }
            const getTSData = async () => {
                await base.getAll("Report/tsperiod/" + selectedValue).then((tsRes) => {
                    // setBankData(budgetRes);
                    setTsLabels(tsRes.map(function (item) {
                        return item.projectName;
                    }));
                    setTsValues(tsRes.map(function (item) {
                        return item.sumBillable;
                    }));
                    setTsValues1(tsRes.map(function (item) {
                        return item.sumUnbillable;
                    }));
                    // setTsValues1(tsRes.map(function (item) {
                    //     return item.sumUnbillable;
                    // }));
                    // const getTSReport = async () => {
                    //     await tokenRefresh(

                    // }
                    setSumBillable(formatNumber(tsRes.reduce(function (prev, current) {
                        return prev + +current.sumBillable;
                    }, 0)))
                    setSumUnbillable(formatNumber(tsRes.reduce(function (prev, current) {
                        return prev + +current.sumUnbillable;
                    }, 0)));
                    setTimeout(() => {
                        setLoading2(false)
                        setLoading3(false);
                    }, 400);


                });
                // setTimeout(() => {
                // }, 500);


            }
            const getTRData = async () => {
                await base.getAll("Report/transactionReport").then((tsRes) => {
                    // setBankData(budgetRes);
                    setTrLabels(tsRes.map(function (item) {
                        return item.name;
                    }));
                    setTrValues(tsRes.map(function (item) {
                        // setVatValue(vatValue + item.rVAT)
                        return item.rVAT;
                    }));
                    setVatValue(tsRes.map(datum => datum.rVAT).reduce((a, b) => a + b))
                    // console.log(sum);
                });
                // console.log("here", trLabels);

                setLoading2(false)

            }
            const getOverviewData = async () => {
                await base.getAll("Report/overview/" + selectedValue).then((overviewRes) => {
                    // setBankData(budgetRes);
                    // setCCIn(formatNumber(overviewRes.ccIn))
                    // setCCOut(formatNumber(overviewRes.ccOut))
                    // setCCNet(formatNumber(overviewRes.ccNet))
                    setLastRefreshed(new Date(overviewRes.lastrefreshed).toLocaleDateString() + ' ' + new Date(overviewRes.lastrefreshed).toLocaleTimeString());


                });
            }
            const getDate = () => {
                // var today = new Date();
                const current = new Date();
                current.setDate(current.getDate() - 3);
                //console.log(current)
                // var month = (today.getMonth() + 1);
                // var month1 = String(month).padStart(2, '0')
                var date = current.getFullYear() + '.' + String(current.getMonth() + 1).padStart(2, '0');
                setPeriodDate(date);
                setLoading4(false)
            }
            const getUsers = async () => {
                await base.getAll("Report/users/" + selectedValue).then((userRes) => {
                    // let data = await response.json();
                    //console.log("userlevel =", user);
                    let detailArr = [];
                    userRes.map(element => {
                        let dropDownEle = { value: element["idUser"], label: element["name"] };
                        detailArr.push(dropDownEle);
                        return 1;
                    });
                    // let dropDownEle = 
                    if (user === '10') {
                        detailArr.push({ value: "0", label: "All" })
                        // //console.log(detailArr)
                        // setSelectedValue("3")
                    }
                    setUsers(detailArr)

                });
            }
            getDate();
            setLoading1(true);
            setLoading2(true);
            setLoading3(true);
            getOverviewData();
            // console.log("user");
            // console.log(user);
            if (user === "10") {
                getTSData();
                getUsers();
                // setLoading2(false);
            }
            if (user === "20") {
                getTRData();
                // console.log("In other")
                // setLoading2(false);

            }
            if (user === "30") {
                getTSData();
                // console.log("In other")
                // setLoading2(false);

            }
            getCCData();

        };


    }, [selectedValue, faRefresh, refreshPage, formatNumber, user])

    // //console.log(bankValues);
    // //console.log(bankData);

    // const options = {
    //     style: 'currency',
    //     currency: 'GBP'
    // };

    const refreshFromFreeAgent = (async () => {
        setLoading4(true);
        setLastRefreshed("Refresh In Progress...");
        await base.getAll("FreeAgent/refresh").then(() => {
            setLastRefreshed("Refreshing Dashboard...");
            setFARefresh(!faRefresh)
        });
    }
    );



    // const currencyFormatter = useCallback((params) => {
    //     return formatNumber(params.value);
    //     // return params.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    // }, [formatNumber]);


    const callRefresh = () => {
        // re-renders the component
        // setExpLoading(true);
        setRefreshPage(!refreshPage);
    };


    const data = {
        labels: bankLabels,
        datasets: [
            {
                label: 'CC In',
                backgroundColor: 'rgb(0,75,75, 0.8)',
                data: bankValues,
                fill: true,          // Don't fill area under the line
                datalabels: {
                    color: '#111',
                    // clamp: true,
                    // paddingBotton: '100px'
                    align: 'top',
                    anchor: 'end',
                    display: smallScreen,
                    formatter: function (value, context) {
                        return value
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
                    },
                    font: {
                        weight: 'bold'
                    }
                    // padding: {
                    //     top: '10px'
                    // }
                }
            }, {
                label: 'CC Out',
                backgroundColor: 'rgba(220, 20, 60, 0.8)',
                data: bankValues1,
                fill: true,          // Don't fill area under the line
                datalabels: {
                    color: '#111',
                    // clamp: true,
                    // paddingBotton: '100px'
                    align: 'top',
                    anchor: 'end',
                    display: smallScreen,
                    formatter: function (value, context) {
                        return value
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
                    },
                    font: {
                        weight: 'bold'
                    }
                    // padding: {
                    //     top: '10px'
                    // }
                }
            }
        ],
    }
    const data1 = {
        labels: tsLabels,
        datasets: [
            {
                label: 'Billable',
                backgroundColor: 'rgba(0, 128, 128, 0.8)',
                data: tsValues,
                fill: true,          // Don't fill area under the line
                datalabels: {
                    color: '#111',
                    // clamp: true,
                    // paddingBotton: '100px'
                    align: 'top',
                    anchor: 'end',
                    display: smallScreen,
                    formatter: function (value, context) {
                        return value
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
                    },
                    font: {
                        weight: 'bold'
                    }
                    // padding: {
                    //     top: '10px'
                    // }
                }
            },
            {
                label: 'Unbillable',
                backgroundColor: 'rgba(139, 197, 197, 0.8)',
                data: tsValues1,
                fill: true,          // Don't fill area under the line
                datalabels: {
                    color: '#111',
                    // clamp: true,
                    // paddingBotton: '100px'
                    align: 'top',
                    anchor: 'end',
                    display: smallScreen,
                    formatter: function (value, context) {
                        return value
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
                    },
                    font: {
                        weight: 'bold'
                    }
                    // padding: {
                    //     top: '10px'
                    // }
                }
            }
        ],
    }

    // const data2 = [
    //     { bank: 'NatWest', vat: 99, forApproval: 2 },
    //     { bank: 'Barclays', vat: 87, forApproval: 1 },
    //     { bank: 'American Express', vat: 121, forApproval: 6 }
    // ]

    const data3 = {
        labels: trLabels,
        datasets: [
            {
                label: 'Name',
                backgroundColor: 'rgba(0, 128, 128, 0.8)',
                data: trValues,
                fill: true,          // Don't fill area under the line
                datalabels: {
                    color: '#111',
                    // clamp: true,
                    // paddingBotton: '100px'
                    align: 'top',
                    anchor: 'end',
                    display: true,
                    // formatter: function (value, context) {
                    //     return value
                    //         .toFixed(2)
                    //         .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
                    // },
                    font: {
                        weight: 'bold'
                    }
                    // padding: {
                    //     top: '10px'
                    // }
                }
            }
        ],
    }


    return (
        <AuthenticatedTemplate >
            {showConfirm &&
                <ConfirmToast
                    show={true}
                    style={{ top: "20%" }}
                    Message={"Would you like to refresh data from FreeAgent?"}
                    handleYes={() => {
                        setHandleConfirm("Yes");
                    }}
                    handleNo={() => {
                        setHandleConfirm("No");
                    }}
                ></ConfirmToast>
            }
            <div style={{ minHeight: "825px" }}>
                <CardHeader>
                    <Row>
                        <Col xs="12" sm="5" xl="9">
                            <AiFillDashboard size={30} className="mb-1" color="#8bc5c5" />&nbsp;&nbsp;
                            <span className="section-heading">Dashboard</span>
                        </Col>
                        <Col xs="12" sm="7" xl="3" className="text-end" >
                            <div className="text-start me-1" >
                                <Row>
                                    <Col xs="10" >
                                        <ReactSelect
                                            id="dropdown_cc"
                                            className="text-lrf"
                                            options={users}
                                            placeholder={""}
                                            // defaultValue={{ label: "CC_OHD", value: "6" }}
                                            // value={selectedValue}
                                            // defaultValue={selectedValue}
                                            // value={selectedValue}
                                            // value={selectedValue}
                                            maxWidth="300px"
                                            // value={options.find(obj => obj.value === selectedValue)}
                                            onChange={handleChange}
                                        // defaultValue="3"
                                        // components={{
                                        //     Option: SingleOption,
                                        //     DropdownIndicator,
                                        //     IndicatorSeparator: () => null,
                                        // }}
                                        />
                                    </Col>
                                    <Col xs="2" className="text-end">

                                        <Button size="sm" color="light border-dark" className="btn-text" style={{ height: "38px" }} onClick={() => callRefresh()}>
                                            <img src={refresh} alt="refresh" />
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                            {/* </ReactSelect> */}
                            {/* <div className="text-end">
                                                <Button id="MyCC" name="MyCC" className={style1} onClick={handleClick}>My CC</Button>
                                                <Button id="OHD" name="OHD" className={style2} onClick={handleClick}>OHD</Button>
                                            </div> */}
                        </Col>
                    </Row>

                </CardHeader >


                <div className="container-fluid">

                    <div className="m-2">
                        <Row className="mt-2">
                            <Col xs="12" lg="12" xl="6">
                                <Card className="mt-2">
                                    <CardHeader>
                                        <Row>
                                            <Col xs="12">
                                                <AiFillPoundCircle size={30} className="mb-2" color="#8bc5c5" />&nbsp;&nbsp;
                                                <span className="portfolio-caption-heading">Profit Centre</span>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardHeader className="" style={{ backgroundColor: "#d0e7e7" }}>
                                        {loading3 ?
                                            <div className="row align-items-center" style={{ height: "45px" }}>
                                                <div className="text-center" >
                                                    <Spinner />
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <Row>
                                                    <Col className="card-sub-text text-center" >Incoming:</Col>
                                                    <Col className="card-sub-text text-center">Outgoing:</Col>
                                                    <Col className="card-sub-text text-center">Profit:</Col>
                                                    <Col style={{ borderLeft: "#aaa solid 1px" }} className="card-sub-text text-center">PC Total:</Col>
                                                </Row>
                                                <Row>
                                                    <Col className="card-sub-text-bold text-center">{ccIn}</Col>
                                                    <Col className="card-sub-text-bold text-center">{ccOut}</Col>
                                                    <Col className="card-sub-text-bold text-center">{ccNet}</Col>
                                                    <Col style={{ borderLeft: "#aaa  solid 1px" }} className="card-sub-text-bold text-center">{ccTotal}</Col>
                                                </Row>
                                            </>
                                        }
                                    </CardHeader>
                                    <CardBody>
                                        {loading1 ?
                                            <div className="row align-items-center" style={{ height: "350px" }}>
                                                <div className="text-center" >
                                                    <Spinner />
                                                </div>
                                            </div>

                                            :
                                            <Bar data={data} style={{ maxHeight: "350px" }} />
                                        }

                                        {/* <AgChartsReact options={{
                                        data: bankData,
                                        theme: {
                                            overrides: {

                                            }
                                        },

                                        series: [
                                            {
                                                type: 'column',
                                                fills: [
                                                    'rgba(175, 175, 175, 0.8)'
                                                ],
                                                stroke: 'rgba(175, 175, 175, 0.8)',
                                                strokeWidth: 0,
                                                // lineDash: [6, 3],

                                                xKey: ['code'],
                                                yKeys: ['balance'],
                                                label: {
                                                    enabled: true,
                                                    placement: 'outside',
                                                },
                                                highlightStyle: {
                                                    item: {
                                                        fill: 'rgba(75, 192, 192, 0.8)',
                                                        stroke: 'black',
                                                        strokeWidth: 2,
                                                    },
                                                },
                                            },

                                        ],
                                        legend: {
                                            enabled: false,

                                        },

                                    }
                                    } /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            {user !== "20" ?
                                <Col xs="12" lg="12" xl="6">
                                    <Card className="mt-2">
                                        <CardHeader>
                                            <Row>
                                                <Col xs="12" sm="6">
                                                    <AiFillClockCircle size={30} className="mb-2" color="#8bc5c5" />&nbsp;&nbsp;
                                                    <span className="portfolio-caption-heading">Time Tracking</span>
                                                </Col>
                                                <Col xs="12" sm="6" >
                                                    <div className="text-end">
                                                        <Button id="Project" name="Project" className={style1} onClick={handleClick}>Project</Button>
                                                        <Button id="Task" name="Task" className={style2} onClick={handleClick}>Task</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardHeader style={{ backgroundColor: "#d0e7e7" }}>
                                            {loading3 ?
                                                <div className="row align-items-center" style={{ height: "45px" }}>
                                                    <div className="text-center" >
                                                        <Spinner />
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    <Row>
                                                        <Col className="card-sub-text text-center">Period:</Col>
                                                        <Col className="card-sub-text text-center">Billable:</Col>
                                                        <Col className="card-sub-text text-center">Unbillable:</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="card-sub-text-bold text-center">{periodDate}</Col>
                                                        <Col className="card-sub-text-bold text-center">{sumBillable}</Col>
                                                        <Col className="card-sub-text-bold text-center">{sumUnbillable}</Col>
                                                    </Row>
                                                </>
                                            }
                                        </CardHeader>
                                        <CardBody>
                                            {loading2 ?
                                                <div className="row align-items-center" style={{ height: "350px" }}>
                                                    <div className="text-center" >
                                                        <Spinner />
                                                    </div>
                                                </div>
                                                :

                                                <Bar data={data1} options={options2} style={{ maxHeight: "350px" }} />
                                            }

                                            {/* <AgChartsReact options={{
                                        data: tsData,
                                        theme: {
                                            overrides: {

                                            }
                                        },

                                        series: [
                                            {
                                                type: 'column',
                                                fills: [
                                                    'rgba(175, 175, 175, 0.8)'
                                                ],
                                                stroke: 'rgba(175, 175, 175, 0.8)',
                                                strokeWidth: 0,
                                                // lineDash: [6, 3],

                                                xKey: ['code'],
                                                yKeys: ['balance'],
                                                label: {
                                                    enabled: true,
                                                    placement: 'outside',
                                                },
                                                highlightStyle: {
                                                    item: {
                                                        fill: 'rgba(75, 192, 192, 0.8)',
                                                        stroke: 'black',
                                                        strokeWidth: 2,
                                                    },
                                                },
                                            },

                                        ],
                                        legend: {
                                            enabled: false,

                                        },

                                    }
                                    } /> */}
                                        </CardBody>
                                    </Card>
                                </Col>
                                :
                                <Col xs="12" lg="12" xl="6">
                                    <Card className="mt-2">
                                        <CardHeader>
                                            <Row>
                                                <Col xs="6">
                                                    <AiFillCreditCard size={30} className="mb-2" color="#8bc5c5" />&nbsp;&nbsp;
                                                    <span className="portfolio-caption-heading">Transaction Reconciliation</span>
                                                </Col>
                                                <Col xs="6" >
                                                    <div className="text-end">
                                                        {/* <Button id="Project" name="Project" className={style1} onClick={handleClick}>Project</Button> */}
                                                        {/* <Button id="Task" name="Task" className={style2} onClick={handleClick}>Task</Button> */}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardHeader style={{ backgroundColor: "#d0e7e7" }}>
                                            {loading3 ?
                                                <div className="row align-items-center" style={{ height: "45px" }}>
                                                    <div className="text-center" >
                                                        <Spinner />
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    <Row>
                                                        {/* <Col className="card-sub-text text-center">Period:</Col> */}
                                                        {/* <Col className="card-sub-text text-center">Billable:</Col> */}
                                                        <Col className="card-sub-text text-center">VAT (To Reconcile)</Col>
                                                        {/* <Col className="card-sub-text text-center">FA Approval:</Col> */}
                                                        {/* <Col className="card-sub-text text-center">Cost Centre:</Col> */}
                                                    </Row>
                                                    <Row>
                                                        <Col className="card-sub-text-bold text-center">{vatValue}</Col>
                                                        {/* <Col className="card-sub-text-bold text-center">{2}</Col> */}
                                                        {/* <Col className="card-sub-text-bold text-center">{14}</Col> */}
                                                        {/* <Col className="card-sub-text-bold text-center">{sumBillable}</Col> */}
                                                        {/* <Col className="card-sub-text-bold text-center">{sumUnbillable}</Col> */}
                                                    </Row>
                                                </>
                                            }
                                        </CardHeader>
                                        <CardBody>
                                            {loading2 ?
                                                <div className="row align-items-center" style={{ height: "350px" }}>
                                                    <div className="text-center" >
                                                        <Spinner />
                                                    </div>
                                                </div>
                                                :

                                                <Bar data={data3} options={options2} style={{ maxHeight: "350px" }} />
                                            }

                                            {/* <AgChartsReact options={{
                                        data: tsData,
                                        theme: {
                                            overrides: {

                                            }
                                        },

                                        series: [
                                            {
                                                type: 'column',
                                                fills: [
                                                    'rgba(175, 175, 175, 0.8)'
                                                ],
                                                stroke: 'rgba(175, 175, 175, 0.8)',
                                                strokeWidth: 0,
                                                // lineDash: [6, 3],

                                                xKey: ['code'],
                                                yKeys: ['balance'],
                                                label: {
                                                    enabled: true,
                                                    placement: 'outside',
                                                },
                                                highlightStyle: {
                                                    item: {
                                                        fill: 'rgba(75, 192, 192, 0.8)',
                                                        stroke: 'black',
                                                        strokeWidth: 2,
                                                    },
                                                },
                                            },

                                        ],
                                        legend: {
                                            enabled: false,

                                        },

                                    }
                                    } /> */}
                                        </CardBody>
                                    </Card>
                                </Col>
                            }
                        </Row>
                        <Row className="mt-4">

                            <Col md="12" lg="6" xl="3" className="mb-1">
                                <div className="card border-left-1 rounded hover-shadow h-100 py-2" style={{ cursor: "pointer" }} onClick={() => refreshFromFreeAgent()}>
                                    <div className="pt-3 pb-3 ps-4 pe-4">
                                        <div className="row align-items-center">
                                            <Col>
                                                <div className="cd-header mb-1">Refresh Data &nbsp;
                                                </div>
                                                <div className="cd-subheader mb-1 text-muted">{lastRefreshed} &nbsp;
                                                </div>

                                            </Col>
                                            <div className="col-auto">
                                                {loading4 ?
                                                    <div className="row align-items-center">
                                                        <div className="text-center" >
                                                            <Spinner />
                                                        </div>
                                                    </div>
                                                    :
                                                    <img src={Refresh} style={{ width: "50px" }} alt="" />

                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col >
                            <Col md="12" lg="6" xl="3" className="mb-1">
                                <div className="card border-left-2 rounded hover-shadow h-100 py-2" style={{ cursor: "pointer" }} onClick={() => openModal()}>
                                    <div className="pt-3 pb-3 ps-4 pe-4">
                                        <div className="row align-items-center">
                                            <Col>
                                                <div className="cd-header mb-1">Invoice Report&nbsp;
                                                </div>
                                                <div className="cd-subheader mb-1 text-muted">Check invoices before batch run &nbsp;
                                                </div>

                                            </Col>
                                            <div className="col-auto">
                                                <img src={CheckOutline} style={{ width: "50px" }} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col >
                            <Col md="12" lg="6" xl="3" className="mb-1">
                                <div className="card border-left-3 rounded hover-shadow h-100 py-2" style={{ cursor: "pointer" }} onClick={() => openModalCreate()}>
                                    <div className="pt-3 pb-3 ps-4 pe-4">
                                        <div className="row align-items-center">
                                            <Col>
                                                <div className="cd-header mb-1">Create Invoices &nbsp;
                                                </div>
                                                <div className="cd-subheader mb-1 text-muted">Batch Monthly Invoice Run &nbsp;
                                                </div>

                                            </Col>
                                            <div className="col-auto">
                                                {loading5 ?
                                                    <div className="row align-items-center">
                                                        <div className="text-center" >
                                                            <Spinner />
                                                        </div>
                                                    </div>
                                                    :
                                                    <img src={Invoice} style={{ width: "50px" }} alt="" />

                                                }                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </Col >

                            <Col md="12" lg="6" xl="3" className="mb-1">
                                <div className="card border-left-4 rounded hover-shadow h-100 py-2" style={{ cursor: "pointer" }} onClick={() => openFreeAgent()}>
                                    <div className="pt-3 pb-3 ps-4 pe-4">
                                        <div className="row align-items-center">
                                            <Col>
                                                <div className="cd-header mb-1">FreeAgent &nbsp;
                                                </div>
                                                <div className="cd-subheader mb-1 text-muted">Open FreeAgent Application &nbsp;
                                                </div>

                                            </Col>
                                            <div className="col-auto">
                                                <img src={FreeAgent} style={{ width: "50px" }} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col >
                        </Row>
                    </div>

                </div>
                {showModal && <InvoiceModal closeModal={closeModal} showModalForm={true} displayModal={true} invCreate={invCreate} loading4={loading4} setLoading5={setLoading5} setLastRefreshed={setLastRefreshed} faRefresh={faRefresh} setFARefresh={setFARefresh} />}

            </div >
        </AuthenticatedTemplate>
    )
}

export default Dashboard